import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Flag from "react-country-flag"
import { useSelector } from "react-redux"

import geodist from "geodist"
import classnames from "classnames"

import { selectCurrentLocation } from "@selectors"
import placeholder from "@assets/images/placeholder.png"
// import ButtonFollow from "@components/ButtonFollow"

import Card from "react-bulma-components/lib/components/card"
import Media from "react-bulma-components/lib/components/media"
import Image from "react-bulma-components/lib/components/image"
import Heading from "react-bulma-components/lib/components/heading"

import styles from "./styles.module.scss"

const User = ({ user, shadowless, marginless, paddingless }) => {
  if (!user) return null

  const currentLocation = useSelector(selectCurrentLocation)

  const distance =
    user._geoloc &&
    currentLocation &&
    currentLocation.latitude &&
    currentLocation.longitude &&
    geodist(
      {
        lat: currentLocation.latitude,
        lon: currentLocation.longitude,
      },
      { lat: user._geoloc.lat, lon: user._geoloc.lng },
      { exact: true, unit: "km" }
    )

  return (
    <Link
      to={`/${user.username}/`}
      className={classnames(styles.User, {
        [styles.User__shadowless]: shadowless,
      })}
      title={user.username}
    >
      <Card
        shadowless={shadowless}
        marginless={marginless}
        paddingless={paddingless}
      >
        {/* <div className={styles.User__heroContainer}>
          <Card.Image
            size="1by1"
            src={user.photoUrl ? user.photoUrl : placeholder}
            alt={`${user.photoUrl} Hero Image`}
            className="cover"
          />
          <div className={styles.User__gradient} />
        </div> */}
        <Card.Content
          className={styles.User__content}
          paddingless={paddingless}
        >
          <Media className="is-vcentered">
            <Media.Item
              renderAs="figure"
              position="left"
              className={classnames("is-relative", styles.User__imageContainer)}
            >
              <Image
                size={64}
                alt={`Image of ${user.username}`}
                src={user.photoUrl ? user.photoUrl : placeholder}
                rounded
                className={classnames("is-square", "user-image")}
                loading={"lazy"}
              />
              {user.countryCode && (
                <Flag
                  countryCode={user.countryCode}
                  svg
                  className={styles.User__flag}
                  alt={`The ${user.countryCode} flag`}
                />
              )}
            </Media.Item>
            <Media.Item className={styles.User__middle}>
              <Heading
                renderAs={"h3"}
                subtitle
                size={5}
                marginless
                className={styles.User__name}
              >
                {user.name}
              </Heading>
              <p className={styles.User_username}>@{user.username}</p>
              <p className={styles.User_location}>
                {user.city}
                {!!distance && <span> &middot; {distance.toFixed(2)}km</span>}
              </p>
            </Media.Item>
            {/* <ButtonFollow user={user} /> */}
          </Media>
        </Card.Content>
      </Card>
    </Link>
  )
}

User.defaultProps = {
  user: {},
  shadowless: false,
  marginless: false,
  paddingless: false,
}

User.propTypes = {
  user: PropTypes.object,
  shadowless: PropTypes.bool.isRequired,
  marginless: PropTypes.bool.isRequired,
  paddingless: PropTypes.bool.isRequired,
}

export default User
